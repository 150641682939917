import React from "react"
import { graphql } from "gatsby"

const NotFoundPage = ({ data, location }) => {
  if (typeof window !== "undefined") {
    window.location = "/"
  }

  return <div></div>
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
